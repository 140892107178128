// Starts Hide Header on on scroll down
var didScroll;
var lastScrollTop = 0;
var delta = 5;
var navbarHeight = $('#stickyNavbar').outerHeight();
$(window).scroll(function(event){
    didScroll = true;
});
setInterval(function() {
    if (didScroll) {
        hasScrolled();
        didScroll = false;
    }
}, 250);
function hasScrolled() {
    var st = $(this).scrollTop();

    // Make sure they scroll more than delta
    if(Math.abs(lastScrollTop - st) <= delta)
        return;

    // If they scrolled down and are past the navbar, add class .nav-up.
    // This is necessary so you never see what is "behind" the navbar.
    if (st > lastScrollTop && st > navbarHeight){
        // Scroll Down
        $('#stickyNavbar').removeClass('nav-down').addClass('nav-up');
    } else {
        // Scroll Up
        if(st + $(window).height() < $(document).height()) {
            $('#stickyNavbar').removeClass('nav-up').addClass('nav-down');
        }
    }

    lastScrollTop = st;
}
// Ends Hide Header on on scroll down

//Start We create the future data slide
setInterval(function () {
    const show = document.querySelector('.mask span[data-show]')
    const next = show.nextElementSibling || document.querySelector('.mask span:first-child')
    const up = document.querySelector('.mask span[data-up]')

    if (up) {
        up.removeAttribute('data-up')
    }

    show.removeAttribute('data-show')
    show.setAttribute('data-up', '')

    next.setAttribute('data-show', '')
}, 3000)
// Ends We create the future data slide

// Start aboutUs data slide
var $slider = $(".slider-banner");
var $slides = $slider.find(".slider-item-banner");
var $navPrev = $(".go-prev");
var $navNext = $(".go-next");
var $startAutoplay = $(".start-autoplay");
var $stopAutoplay = $(".stop-autoplay");
var slidesNum = $slides.length;
var prevSlideID = null;
var currentSlideID = 0;
var isAnimating = false;
var isAutoPlay = false;
function init() {
    TweenLite.set($slides, {
        autoAlpha: 0
    });
    $navPrev.on("click", gotoPrevSlide);
    $navNext.on("click", gotoNextSlide);
    $startAutoplay.on("click", startAutoPlay);
    // $stopAutoplay.on("click", stopAutoPlay);
    gotoSlide(0, 0);
    startAutoPlay()
}
function gotoPrevSlide() {
    var slideToGo = currentSlideID - 1;
    if (slideToGo <= -1) {
        slideToGo = slidesNum - 1;
    }
    // stopAutoPlay();
    gotoSlide(slideToGo, 1, "prev");
}
function gotoNextSlide() {
    var slideToGo = currentSlideID + 1;
    if (slideToGo >= slidesNum) {
        slideToGo = 0;
    }
    // stopAutoPlay();
    gotoSlide(slideToGo, 0, "next");
}
function gotoSlide(slideID, _time, _direction) {
    if (!isAnimating) {
        isAnimating = true;
        prevSlideID = currentSlideID;
        currentSlideID = slideID;
        var $prevSlide = $slides.eq(prevSlideID);
        var $currentSlide = $slides.eq(currentSlideID);
        var time = 180;
        if (_time !== null) {
            time = _time;
        }
        var direction = "next";
        if (_direction != null) {
            direction = _direction;
        }
        if (direction == "next") {
            TweenLite.to($prevSlide, time, {
                delay: 0.2,
                autoAlpha: 0,
                y: 20
            });
            TweenLite.fromTo($currentSlide, time, {
                autoAlpha: 0,
                y: 20
            }, {
                autoAlpha: 1,
                y: -20
            });
            slideIn($currentSlide, time, currentSlideID);
        } else {
            TweenLite.to($prevSlide, time, {
                autoAlpha: 0,
                y: 20
            });
            TweenLite.fromTo($currentSlide, time, {
                autoAlpha: 0,
                y: 20
            }, {
                autoAlpha: 1,
                y: -20
            });

        }
        TweenLite.delayedCall(time, function () {
            isAnimating = false;
        });
    }
}
function play() {
    gotoNextSlide();
    TweenLite.delayedCall(4, play);
}
function startAutoPlay(immediate) {
    if (immediate != null) {
        immediate = false;
    }

    if (immediate) {
        gotoNextSlide();
    }
    TweenLite.delayedCall(0, play);
}
function slideIn(e, t, id) {
    var tl_bn = new TimelineLite();
    tl_bn
        .from(e.find('.title'), 1, { autoAlpha: 0, scaleX: 1, scaleY: 1, y: 20 })
        .fromTo(e.find('.title p'), t, { autoAlpha: 0 }, { autoAlpha: 1 })
        .from(e.find('.caption p'), 1, { autoAlpha: 0, scaleY: 1, y: 20 })
        // .fromTo(e.find('.caption p'), t, { autoAlpha: 0 }, { autoAlpha: 1 })
        .to({}, 1800, {})
        // .to(e.find('.caption p'), t, { autoAlpha: 0 }, { autoAlpha: 1 })
        .from(e.find('.caption p'), t, { autoAlpha: 1, scaleY: 1, y: -20 })
        .to(e.find('.title p'), t, { autoAlpha: 0 }, { autoAlpha: 1 })
        .from(e.find('.title'), t, { scaleX: 1, scaleY: 1, y: -20, delay: 0.4 })
    return tl_bn;
}
init();
// Ends aboutUs data slide
AOS.init();

$('#owl-carousel').owlCarousel({
    margin: 16,
    dots: false,
    nav: false,
    items: 3,
    autoplay: true,
    loop: true,
    center: true,
    autoplayTimeout:4000,
    responsiveClass:true,
    responsive:{
        0:{
            items:1,
        },
        992:{
            items:1,

        },
        1024:{
            items:3,
        },
    }
})


$('.menu a:last-child').click(function(){
    $('.menu a').not(':last-child').toggleClass('hidden show') ;
})

var $root = $('html, body');

$('a[href^="#"]').click(function () {
    $root.animate({
        scrollTop: $( $.attr(this, 'href') ).offset().top
    }, 500);

    return false;
});
